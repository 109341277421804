var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("guide", {
    attrs: {
      "props-filters": { typeId: 1 },
      addPermissionString: "system:add",
      editPermissionString: "system:edit",
      deletePermissionString: "system:delete",
      viewPermissionString: "system:view",
      propsProductionId: "",
      propsPlatformId: "",
      propsUrl: _vm.url,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }