<template>
  <guide :props-filters="{ typeId: 1 }" addPermissionString='system:add' editPermissionString='system:edit' deletePermissionString='system:delete' viewPermissionString='system:view' propsProductionId="" propsPlatformId="" :propsUrl="url"></guide>
</template>

<script>
import Guide from '@/views/system/modules/guide/guide-base'
export default {
  components: {
    Guide,
  },
  data() {
    return {
      url: {
        list: '/guide/list',
        delete: '/guide/delete',
        deleteBatch: '/guide/delete_batch',
        edit: '/guide/edit',
        add: '/guide/add',
      },
    }
  },
  methods: {},
  created() {},
}
</script>

<style lang='less'>
</style>
